/* * { */
/*   box-sizing: border-box; */
/*   margin: 0; */
/*   padding: 0; */
/* } */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Karla:wght@400;500&family=Nunito:ital@0;1&family=Rubik:ital@0;1&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
body {
  font-family: Inter, sans-serif;
  /* background-color: #282a36; */
  /* color: #f8f8f2; */
}

.box {
  padding: 20px;
  background-color: var(--color-bg-alt);
}

.container {
  padding: 20px;
  min-height: 70vh;
  display: grid;
  /* flex-direction: column; */
  /* flex-wrap: wrap; */
  grid-template-columns: min(300px, max(200px, calc(50px + 20vw))) calc(30vw - 250px) min(850px, max(520px, 50vw)) auto;
  grid-template-rows: auto auto 1fr 10fr;
  grid-template-areas:
  "nav . header ."
  "nav . main ."
  ". . main ."
  ". . . ."
}

.content h1 {
  font-size: 2em;
}

.content h2 {
  font-size: 1.8em;
}

.content h3 {
  font-size: 1.6em;
}

.title h1 {
  font-style: italic;
  font-weight: 400;
  font-family: "Space Mono", serif;
  font-size: 2.8em;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 2rem;
}

.title .links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 3vw;
}

.title .links a {
  font-family: "Space Mono", serif;
  border-bottom: solid 2px var(--color-blossom-dark);
  padding-right: 4vw;

}
.title .links a:hover {
  background-color: var(--color-bg-alt);
  border-bottom: 2px solid var(--color-blossom-dark);
}

.content img {
  display: inherit;
  margin: 10px auto;
  width: 80%;
}
.navbar {
  grid-area: nav;
  padding-bottom: 30px;
}

.navbar a {
  color: var(--color-text);
}

.title {
  min-height: 0;
  grid-area: header;
  flex-grow: 0;
}

.content {
  height: auto;
  grid-area: main;
  min-height: 0;
  padding: 30px;
}

.content p {
  line-height: 1.5;
  font-size: 1.75rem;
}

.

h1 {
  font-size: 2rem;
}

/* a { */
/*   color: #8be9fd; */
/*   text-decoration: none; */
/* } */

.navbar a {
  border: 0px;
  border-style: solid;
  border-bottom: 2px solid var(--color-text);
}
.navbar a:hover {
  background-color: var(--color-bg-alt);
  border-bottom: 2px solid var(--color-blossom-dark);
}

.navbar a.active {
  background-color: var(--color-blossom-dark);
  border-bottom: 2px solid var(--color-blossom-dark);
  color: var(--color-blossom-light);
}


@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .navbar {
    order: 2;
  }

  .title {
    order: 1;
  }

  .content {
    order: 3;
  }
}

.profile-pic {
  width: 100%;
  max-width: 150px;
  height: auto;
  border-radius: 50%;
  display: block;
  margin-bottom: 1rem;
}

.nav-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.nav-links a {
  display: inline-block;
  position: relative;
  padding: 2px 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 90% 100%, 10% 100%, 0% 100%, 0% 0%);
}

.essay-list {
  list-style: none;
}

.recaptcha {
  font-size: 0.8rem;
  color: #6272a4;
}

.grecaptcha-badge {
    visibility: hidden;
}

